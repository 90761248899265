/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:d52e5ef7-bc2d-44ca-b6e2-332e89db70d6",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_mAPI8sKP9",
    "aws_user_pools_web_client_id": "1ig6n4rlg23rgfeqsk4at0og4l",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://tce9wcxl6e.execute-api.us-east-1.amazonaws.com/master",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://mi2zjpo7afewxdq3ggpf5zasku.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_appsync_apiKey": "da2-kmfinoycdzbzfcvfnojukiula4",
    "aws_user_files_s3_bucket": "the-smoke-show-auth223902-master",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
